import "./HomeScreen.scss";
import { useHistory } from "react-router-dom";
import { PATH } from "../../constants/RouteConstants";
import { CONSTANTS, URL_CONST } from "../../constants/application.constants";
import { COGNITO_CONST } from "../../constants/cognito.constants";
import axios from "axios";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const HomeScreen = () => {
  const history = useHistory();
  let isLoggedId = false;
  let idToken: any, refreshToken: any;

  const path = history.location.pathname;

  const redirectToApplication = (appName: any) => {
    console.log(isLoggedId);
    if (isLoggedId) {
      if (appName === "capital") {
        window.location.replace(
          URL_CONST.CAPITAL_URL +
            "?id_token=" +
            idToken +
            "&refresh_token=" +
            refreshToken
        );
      }
      if (appName === "sample") {
        window.location.replace(
          URL_CONST.SAMPLE_URL +
            "?id_token=" +
            idToken +
            "&refresh_token=" +
            refreshToken
        );
      }
    } else {
      history.push(PATH.LOGIN_PAGE);
    }
  };

  if (
    localStorage.getItem(CONSTANTS.ID_TOKEN) &&
    localStorage.getItem(CONSTANTS.REFRESH_TOKEN)
  ) {
    isLoggedId = true;
  }

  if (
    path.includes("/callback") &&
    history.location.search.includes("code") &&
    !isLoggedId
  ) {
    const code = history.location.search.split("code=")[1];
    /* Get Tokens from Code */
    axios
      .post(
        COGNITO_CONST.DOMAIN_PREFEIX + "/oauth2/token",
        new URLSearchParams({
          grant_type: "authorization_code",
          client_id: COGNITO_CONST.COGNITO_CLIENT_ID,
          code: code,
          redirect_uri: COGNITO_CONST.REDIRECT_URL,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        console.log("response", response);
        if (response.data) {
          refreshToken = response.data.refresh_token;
          idToken = response.data.id_token;
          localStorage.setItem(CONSTANTS.ID_TOKEN, idToken);
          localStorage.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
          isLoggedId = true;
          /**
           * If callback url is present along with tokens, application will redirect to callbacnk url
           */
          if (localStorage.getItem(CONSTANTS.CALLBACK_URL)) {
            const callbackUrl = localStorage.getItem(CONSTANTS.CALLBACK_URL);
            if (
              callbackUrl?.includes("id_token=") &&
              callbackUrl?.includes("refresh_token=")
            ) {
              window.location.replace(callbackUrl);
            } else {
              window.location.replace(
                callbackUrl +
                  "?id_token=" +
                  idToken +
                  "&refresh_token=" +
                  refreshToken
              );
            }
            localStorage.removeItem(CONSTANTS.CALLBACK_URL);
          } else {
            redirectToApplication("capital");
          }
        }
      })
      .catch((error) => {
        console.log("error.response.data::", error.response.data);
      });
  } else {
    /* Check for tokens */
    idToken = localStorage.getItem(CONSTANTS.ID_TOKEN);
    refreshToken = localStorage.getItem(CONSTANTS.REFRESH_TOKEN);
    if (idToken && refreshToken) {
      isLoggedId = true;
      redirectToApplication("capital");
    } else {
      isLoggedId = false;
      history.push(PATH.LOGIN_PAGE);
    }
  }

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 101,
      color: "#fff",
    },
  }));

  const Loader = () => {
    const styles = useStyles();
    return (
      <div>
        <Backdrop className={styles.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  };

  return (
    <div className="home-page-wrap">
      <div className="container">
        <Loader />
      </div>
    </div>
  );
};

export default HomeScreen;
