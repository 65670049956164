import "./About.scss";

const About = () => {
  return (
    <div className="about-wrap">
      <h2 className="title">WPA - Wonderful</h2>
    </div>
  );
};

export default About;
