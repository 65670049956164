import { PATH } from "../constants/RouteConstants";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomeScreen from "../components/Home/HomeScreen";
import LoginScreen from "../components/LoginScreen/LoginScreen";
export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PATH.HOME}>
          <HomeScreen />
        </Route>
        <Route exact path="/login">
          <LoginScreen />
        </Route>
        <Route exact path="/callback">
          <HomeScreen />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
