import React from "react";
import "./LoginScreen.scss";
import About from "../About/About";
import { COGNITO_CONST } from "../../constants/cognito.constants";
import { useHistory } from "react-router-dom";
import { PATH } from "../../constants/RouteConstants";
import { CONSTANTS } from "../../constants/application.constants";

const LoginScreen = () => {
  const history = useHistory();
  localStorage.removeItem(CONSTANTS.ID_TOKEN);
  localStorage.removeItem(CONSTANTS.REFRESH_TOKEN);
  // Store callback URL in local storage
  const params = history.location.search;
  if (params.includes("callback_url")) {
    let callbackurl = params.replaceAll("?callback_url=", "");
    callbackurl += history.location.hash;
    localStorage.setItem(CONSTANTS.CALLBACK_URL, callbackurl);
  }
  const login = () => {
    const congitoURL =
      COGNITO_CONST.DOMAIN_PREFEIX +
      COGNITO_CONST.AUTHORIZE_URL +
      "&client_id=" +
      COGNITO_CONST.COGNITO_CLIENT_ID +
      "&redirect_uri=" +
      COGNITO_CONST.REDIRECT_URL;

    window.location.replace(congitoURL);
    history.push(PATH.HOME);
  };

  return (
    <div className="login-screen-wrap">
      <div className="container">
        <About />
        <div className="button-section">
          <button className="button" type="button" onClick={login}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
