export const URL_CONST = {
  CAPITAL_URL: process.env.REACT_APP_CAPITAL_PROJECT_URL,
  SAMPLE_URL: process.env.REACT_APP_SAMPLE_PROJECT_URL,
};

export const CONSTANTS = {
  ID_TOKEN: "AUTH_TOKEN",
  CALLBACK_URL: "CALLBACK_URL",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};
